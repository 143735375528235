import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./CurveHeroBg.module.scss"

const CurveHeroBg = ({ heading, subHeading }) => (
  <section className={styles.section}>
    <Container>
      <h1 className="main-banner-heading-55 pb-2">{heading}</h1>
      {subHeading && <div>{subHeading}</div>}
    </Container>
  </section>
)

export default CurveHeroBg
